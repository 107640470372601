import type { ScheduleInterface } from '~/EastclinicVueApi';
type WorkDaysType = Record<number, Record<number, Record<number, ScheduleInterface>>>;
// interface IScheduleStore {
//   setSchedules(data: ScheduleInterface[]):void;
//   getSlots(clinicId:number, doctorId:number, day:number):number[]|null;
// }
// function removeDuplicates<T>(array: T[]): T[] {
//   const seen = new Set<string>();
//   return array.filter((item) => {
//     const serializedItem = JSON.stringify(item);
//     return seen.has(serializedItem) ? false : seen.add(serializedItem);
//   });
// }
function mergeDeep<T>(target: T, source: T): T {
  for (const key in source) {
    if (source[key] instanceof Object && key in target) {
      mergeDeep(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
}
export const useScheduleStore = defineStore('schedule', () => {
  const schedules = ref<ScheduleInterface[]>([]);
  const schedulesByDoctorsDaysClinics = ref<WorkDaysType>({});

  const getSlots = (clinicId:number, doctorId:number, day:number):number[]|null => {
    return (schedulesByDoctorsDaysClinics.value?.[doctorId]?.[day]?.[clinicId]?.slots) ? schedulesByDoctorsDaysClinics.value?.[doctorId]?.[day]?.[clinicId]?.slots : null;
  };

  const setSchedulesByDoctorsDays = () => {
    const workDays: WorkDaysType = {};
    for (const s in schedules.value.sort((a:ScheduleInterface, b:ScheduleInterface) => { // sortAsc
      if (a.doctorId !== b.doctorId) {
        return a.doctorId - b.doctorId;
      }
      return a.date - b.date;
    })) {
      const schedule = schedules.value[s];
      workDays[schedule.doctorId] ??= {};
      workDays[schedule.doctorId][schedule.date] ??= {};
      workDays[schedule.doctorId][schedule.date][schedule.clinicId] = schedule;
    }
    schedulesByDoctorsDaysClinics.value = mergeDeep(schedulesByDoctorsDaysClinics.value, workDays);
  };
  const workDays = (doctorId: number, clinicId?:number|null) => {
    if (schedules.value.length === 0) {
      return {};
    }

    const schedulesFiltered = schedules.value.filter((shd:ScheduleInterface) => {
      return (shd.doctorId === doctorId && (!clinicId || (clinicId && shd.clinicId === clinicId)) && shd.slots?.length > 0);
    });

    if (schedulesFiltered.length === 0) {
      return {};
    }
    const result: Record<number, number> = {};
    schedulesFiltered.forEach((shd: ScheduleInterface) => {
      result[shd.date] = shd.date;
    });
    return result;
  };
  const getWorkCalendar = (doctorId:number, clinicId?:number|null) => {
    const calendar = getCalendar(30);
    const workDaysForDoctor = workDays(doctorId, clinicId);
    if (Object.keys(workDaysForDoctor).length === 0) { return calendar; }
    for (const day of calendar) {
      if (workDaysForDoctor[Number(day.timestamp)]) {
        day.workingDay = true;
      }
    }
    return calendar;
  };

  const doctorSchedules = (doctorId: number, withSlots:boolean = false):ScheduleInterface[] => {
    let doctorSchedulesData = schedules.value.filter((shd:ScheduleInterface) => shd.doctorId === doctorId);
    if (withSlots) {
      doctorSchedulesData = doctorSchedulesData.filter((shd:ScheduleInterface) => shd.slots.length > 0);
    }
    return doctorSchedulesData;
  };

  const nearestWorkDay = (doctorId:number, clinicId:number|null = null):number => {
    return (Object.values(workDays(doctorId, clinicId))?.[0]) ?? null;
  };

  return {
    workDays,
    getSlots,
    getWorkCalendar,
    nearestWorkDay,
    doctorSchedules,
    schedulesByDoctorsDaysClinics,
    schedules,
    setSchedulesByDoctorsDays
  };
});
