<script setup lang="ts">
  import EcButton from '../../../UI/Buttons/EcButton.vue';

  const pageInfoStore = usePageInfoStore();
  const bookingStore = useBookingStore();
  const cart = useCartStore();

  const { showModalBooking, showLeaveMessage } = storeToRefs(bookingStore);
  if (!bookingStore) { throw new Error('not have bookingState by BookingState'); }

  const closeBookingDialog = () => {
    showModalBooking.value = false;
    showLeaveMessage.value = false;
    cart.clear();
    bookingStore.clinic = null;
    bookingStore.slot = 0;
    bookingStore.doctor = null;
  };

  const leaveMessageDialog = ref(null);
  onClickOutside(leaveMessageDialog, () => {
    closeBookingDialog();
  });

  const backToBooking = () => {
    showLeaveMessage.value = false;
    showModalBooking.value = true;
  };

</script>

<template>
  <div ref="leaveMessageDialog" class="booking__dialog__leave" style="position: relative;">
    <div class="booking__dialog__leave_container">
      <div class="booking__dialog__leave_head text-semibold">
        Вы действительно хотите прервать запись?
      </div>
      <div class="booking__dialog__leave_desc">
        Если у вас возникли вопросы, позвоните нам по телефону:<br>
        <NuxtLink class="booking__dialog__leave_phone" :href="pageInfoStore.getLinkPhone">
          {{ pageInfoStore.getFormattedMainPhone }}
        </NuxtLink>
      </div>
      <div class="">
        <EcButton
          large
          class="secondary-l text-semibold full-width mb-4"
          @click="closeBookingDialog()"
        >
          Прервать запись
        </EcButton>

        <EcButton
          large
          class="primary text-semibold full-width"
          @click="backToBooking()"
        >
          Вернуться к записи
        </EcButton>
      </div>
    </div>
    <IconClose class="icons close modal-card-title_close booking__dialog__leave_close pointer" style="position: absolute; right: 20px; top: 20px" @click="closeBookingDialog" />
  </div>
</template>

<style scoped>

</style>
