import type { CalendarInterface } from '../EastclinicVueApi';
export default function (countDays:number) {
  const dates = [];
  const startOfDay = new Date();
  startOfDay.setUTCHours(-3, 0, 0, 0);

  for (let i = 0; i < countDays; i++) {
    const date = new Date(startOfDay.getTime() + i * 24 * 60 * 60 * 1000);

    const timestamp = date.getTime() / 1000;
    const dayOfMonth = date.getDate();
    const dayOfWeek = date.toLocaleDateString('ru-Ru', { weekday: 'short' });
    const monthNumber = date.getMonth() + 1;

    const dateInfo:CalendarInterface = {
      dayOfMonth,
      dayOfWeek,
      monthNumber,
      timestamp,
      workingDay: false
    };
    dates.push(dateInfo);
  }

  return dates;
}
