<script setup lang="ts">
  const cart = useCartStore();
</script>

<template>
  <div
    v-for="(good,key) in cart.services"
    :key="key"
    class="doctor-info__services__cart-list"
  >
    <div class="mb-2 text-medium">
      {{ good.service.name }}
      <span v-if="good.service.option !== ''" class="text-secondary">({{ good.service.option }})</span>
    </div>
    <div class="doctor-info__services list cart">
      <div class="doctor-info__services_title serv-title">
        <div class="services__count-switcher">
          <IconMinus
            class="cart-button"
            @click="cart.remove(good.service)"
          />
          <span class="text-regular">{{ good.count }}</span>
          <IconPlus
            class="cart-button"
            @click="cart.add(good.service)"
          />
        </div>
      </div>
      <div
        v-if="good.service.custom_price && good.service.custom_price < good.service.price"
        class="doctor-info__services_price serv-price"
      >
        <div class="doctor-info__services_price_current text-semibold">
          {{ good.service.custom_price * good.count }}₽
        </div>
        <div class="doctor-info__services_price_discount_price">
          <span class="doctor-info__services_off_price list">{{ good.service.price }}₽</span>
          <span class="doctor-info__services_discount_percent list">
            -{{ good.service.discount }}%
          </span>
        </div>
      </div>
      <div v-else class="doctor-info__services_price serv-price">
        <div class="doctor-info__services_price_current text-semibold text-regular">
          {{ (good.service.price > good.service.custom_price) ? good.service.price * good.count : good.service.custom_price * good.count }}₽
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .cart-button {
    width: 11px;
    cursor: pointer;
  }
</style>
