<script setup lang="ts">
  interface Props {
    mark: {
      title: string
      icon: 'crown'
    }
    isPage?: boolean
  }

  defineProps<Props>();

  const getIcon = (icon: string) => {
    switch (icon) {
      case 'crown': return 'HeadDoctor';
      default: return 'HeadDoctor';
    }
  };
</script>

<template>
  <div class="doctor-mark" :class="{'doctor-mark--page': isPage}">
    <component
      :is="`IconBadge${getIcon(mark.icon)}`"
      :class="isPage ? 'doctor-mark-icon--page' : 'doctor-mark-icon'"
    />
    <div :class="isPage ? 'doctor-mark-text--page' : 'doctor-mark-text'">
      {{ mark.title }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.doctor-mark {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px;
  border-radius: 15px;
  background-color: #F1F7FF;
}
.doctor-mark--page {
  justify-content: center;
}
.doctor-mark-icon {
  font-size: 16px;
  margin: 0px;
  min-width: 16px;
  color: #2D7FF9;
}
.doctor-mark-icon--page {
  font-size: 20px;
  margin: 0px;
  min-width: 20px;
  color: #2D7FF9;
}
.doctor-mark-text {
  color: #2D7FF9;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.doctor-mark-text--page {
  color: #2D7FF9;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
</style>
