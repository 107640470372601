<script setup lang="ts">
  import Modal from '~/UI/Modal.vue';
  import BookingFormWithChoiceView from '~/components/Booking/views/BookingFormWithChoiceView.vue';
  import BookingSuccessMessageView from '~/components/Booking/views/BookingSuccessMessageView.vue';
  import LeaveMessageView from '~/components/Booking/views/LeaveMessageView.vue';

  const bookingStore = useBookingStore();
</script>

<template>
  <div class="booking-modal">
    <Teleport to="body">
      <Modal v-model:visible="bookingStore.showModalBooking" :click-outside-off="true">
        <BookingFormWithChoiceView />
      </Modal>
      <Modal v-model:visible="bookingStore.showBookingSuccessMessage">
        <BookingSuccessMessageView />
      </Modal>
      <Modal v-model:visible="bookingStore.showLeaveMessage">
        <LeaveMessageView />
      </Modal>
    </Teleport>
  </div>
</template>
