<script setup lang="ts">
  interface Props {
    experience?: string
    minAge?: string
    url: string
    isPregnantPatient: boolean
    type: 'page'|'item'
  }

  const props = defineProps<Props>();

  const { doctorReviewsCount } = storeToRefs(useDoctorsStore());

  const getMinAge = (text: string) => {
    const otIndex = text.indexOf('от ');
    const sIndex = text.indexOf('с ');

    if (otIndex !== -1) {
      return text.substring(otIndex);
    }

    if (sIndex !== -1) {
      return text.substring(sIndex);
    }
    return '';
  };
  // Юнит тесты писать не буду, потому что нет возможности
  // Проверено на этом:
  // Принимает взрослых от 18 лет
  // Принимает взрослых с 18 лет
  // Принимает взрослых и детей от 18 лет
  // Принимает взрослых и детей с 18 лет
  // Принимает с 18 месяцев
  // Принимает от 18 месяцев

  const getReviewsCase = computed(() => {
    if (props.type === 'item') {
      return;
    }
    const count = doctorReviewsCount.value;
    const titles = ['отзыв', 'отзыва', 'отзывов'];
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]];
  });
</script>

<template>
  <div class="experience-container">
    <div class="experience-top">
      <div v-if="experience" class="table-el">
        <span :class="`text-top-${type}`">{{ experience.replace('Стаж ', '') }}</span>
        <span class="text-bottom">стаж</span>
      </div>
      <div v-if="minAge" class="table-el">
        <span :class="`text-top-${type}`">{{ getMinAge(minAge) }}</span>
        <span class="text-bottom">принимает</span>
      </div>
      <div v-if="type === 'item'" class="table-el">
        <NuxtLink :to="url" class="table-link">
          Подробнее <br> о враче
        </NuxtLink>
      </div>
      <div v-else-if="doctorReviewsCount !== 0" class="table-el">
        <NuxtLink to="#reviews" style="text-decoration: none;">
          <div class="reviews-top">
            <IconDoctorItemReviews :filled="true" class="icon-reviews" />
            <span class="reviews-count">{{ doctorReviewsCount }}</span>
          </div>
          <span class="reviews-bottom">{{ getReviewsCase }}</span>
        </NuxtLink>
      </div>
    </div>
    <div v-if="isPregnantPatient" :class="`experience-bottom ${type === 'page' ? 'text-top-page':''}`">
      Работает с беременными
    </div>
  </div>
</template>

<style scoped lang="scss">
.experience-container {
  grid-area: experience;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid #E4E7EF;
  padding: 16px 12px;
  max-width: 404px;
  margin-bottom: 16px;
  @media (max-width: 585px) {
    max-width: none
  }
}
.experience-top {
  display: flex;
  align-items: center;
  width: 100%;
}
.experience-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid #E4E7EF;
  color: #171E36;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.table-el {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  height: 36px;
  &:not(:last-child) {
    border-right: 1px solid #E4E7EF;
  }
}
.text-top-item {
  color: #171E36;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.text-top-page {
  color: #171E36;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.text-bottom {
  color: #666E81;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
.table-link {
  color: #2D7FF9 !important;
  text-decoration: none !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.icon-reviews {
  font-size: 20px;
  margin: 0;
}
.reviews-top {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-bottom: -4px;
}
.reviews-count {
  color: #2D7FF9;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.reviews-bottom {
  color: #2D7FF9;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
</style>
