<script setup lang="ts">
  const props = defineProps({
    clickOutsideOff: {
      type: Boolean,
      default: false
    }
  });

  const visible = defineModel<boolean>('visible');

  onMounted(() => {});

  const refModal = ref(null);
  // onClickOutside(refModal, () => {
  //   if (!props.clickOutsideOff) {
  //     visible.value = false;
  //   }
  // });

  const clickOnOverlay = () => {
    if (!props.clickOutsideOff) {
      visible.value = false;
    }
  };

// todo debug click outside
</script>

<template>
  <TransitionFade>
    <teleport to="body">
      <div v-if="visible" class="modal-overlay" @click.self="clickOnOverlay">
        <div ref="refModal" class="modal-window">
          <slot name="default"></slot>
          <slot name="close">
          </slot>
        </div>
      </div>
    </teleport>
  </TransitionFade>
</template>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-window {
  background: #fff;
  max-width: 670px;
  max-height: 80vh;
  border-radius: 30px;
  overflow: auto;
  @media (max-width: 680px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
  }
}
</style>
