<script setup lang="ts">
  import type { ServiceData } from '~/EastclinicVueApi';

  interface Props {
    services: ServiceData[];
    doctorId?: number;
    fromBooking?: boolean;
  }

  interface Emits {
    (e: 'close'): void
    (e: 'selected'): void
  }

  const props = defineProps<Props>();

  const emit = defineEmits<Emits>();

  const cart = useCartStore();

  const doctorsStore = useDoctorsStore();

  const closeServicesModal = () => {
    emit('close');
    cart.clear();
  };
  const clickOnButton = () => {
    if (props.fromBooking) {
      emit('close');
    }
    emit('selected');
  };

  const commentBody = computed(() => {
    return doctorsStore.tryParseComment(props.doctorId);
  });
</script>

<template>
  <Teleport to="body">
    <div class="overlay" :style="{backgroundColor: `${fromBooking?'transparent':''}`}" @click.self="closeServicesModal">
      <div class="modal">
        <div class="modal-title">
          <span class="modal-title-text">Услуги врача</span>
          <div class="close-container" @click.stop="closeServicesModal">
            <IconClose class="icon-close" />
          </div>
        </div>
        <div class="services-list">
          <div
            v-for="service in services"
            :key="service.serviceId"
          >
            <div
              class="service-item"
              @click.prevent="cart.toggleService(service)"
            >
              <div class="service-item-content">
                <span class="service-top-text">
                  {{ service.name }} <span style="color: #878FA2">{{ Boolean(service.option) ? service.option : service.default_option }}</span>
                </span>
                <div class="service-bottom">
                  <span v-if="!service.custom_price || service.price === service.custom_price" class="service-price">{{ service.price }} ₽</span>
                  <span v-else-if="service.custom_price > service.price" class="service-price">{{ service.custom_price }} ₽</span>
                  <div v-else>
                    <span class="service-price sale">{{ service.price }} ₽</span>
                    <span class="sale-container">
                      <span class="sale-text">{{ service.custom_price }} ₽</span>
                      <IconDoctorServiceSale filled class="sale-icon" />
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <svg
                  v-if="!cart.isSelected(service)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <circle cx="12.5" cy="12" r="11.5" stroke="#D5D7D9"></circle>
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <circle cx="12.5" cy="12" r="12" fill="#2D7FF9"></circle>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1508 7.31839C19.6105 7.74838 19.6172 8.45187 19.1657 8.88968L11.1449 16.6675C10.9255 16.8802 10.6256 17 10.3125 17C9.99941 17 9.69948 16.8802 9.48012 16.6674L5.83429 13.1319C5.38281 12.6941 5.38949 11.9906 5.8492 11.5606C6.30891 11.1306 7.04757 11.137 7.49905 11.5748L10.3125 14.3032L17.501 7.33255C17.9525 6.89474 18.6911 6.8884 19.1508 7.31839Z" fill="white"></path>
                </svg>
              </div>
            </div>
            <ClientOnly>
              <div v-if="useContext() === 'operator'" style="margin: 0px 16px 0px 16px">
                <LazyOperatorEditor
                  :comment-body="commentBody"
                  :commentable-id="doctorId"
                  :service-id="service.serviceId"
                  :comment-id="doctorsStore.doctorsCommentsMap.get(doctorId ?? 0)?.id"
                  :block-id="doctorsStore.tryParseBlockId(doctorId)"
                />
              </div>
            </ClientOnly>
          </div>
        </div>
        <div class="button-container">
          <button type="button" class="service-button" @click="clickOnButton">
            <div v-if="cart.count() === 0" class="no-services-container">
              <span class="no-services-top-text">Записаться</span>
              <span class="no-services-bottom-text">без выбора услуги</span>
            </div>
            <div v-else class="has-services-container">
              <div class="count-container">
                <IconCart filled class="cart-icon" />
                <span class="count-text">{{ cart.count() }}</span>
              </div>
              <span class="has-services-text">Перейти к записи</span>
              <IconChevronDown class="icon-chevron" />
            </div>
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
.modal {
  z-index: 1002;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 584px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 2px 24px 0px rgba(135, 143, 162, 0.15);
  padding: 32px 16px;
  border-radius: 30px;
  overflow: auto;
  max-height: 100vh;
  @media (max-width: 584px) {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 32px 4px;
    justify-content: start;
    /* background-color: red; */
  }
}
.overlay {
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-title {
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: space-between;
  padding: 0px 25px 11px 16px;
  @media (max-width: 584px) {
    padding: 0px 15px 11px 15px;
  }
}
.modal-title-text {
  color: #171E36;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  @media (max-width: 584px) {
    font-size: 20px;
    line-height: 26px;
  }
}
.close-container {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #F2F3F6;
  cursor: pointer;
}
.icon-close {
  font-size: 20px;
  margin: 0;
  color: #262633;
}
.services-list {
  @include scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 446px;
  overflow-y: scroll;
  margin-top: 0px;
  @media (max-width: 584px) {
    max-height: 90%;
    flex-grow: 1;
  }
}
.service-item {
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 20px;
  &:hover {
    background-color: #F5F6F9;
  }
}
.service-item-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 8px;
}
.service-top-text {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.service-price {
  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.sale {
  text-decoration: line-through;
}
.sale-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 10px;
  background-color: #10C44C;
}
.sale-text {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.sale-icon {
  font-size: 16px;
  margin: 0px;
}
.button-container {
  padding: 20px 16px 0px 16px;
}
.service-button {
  display: flex;
  width: 100%;
  height: 56px;
  padding: 0px 19.55px;
  border-radius: 15px;
  background-color: #2D7FF9;
  box-shadow: 0px 4px 16px 0px rgba(0, 72, 255, 0.35);
}
.no-services-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.no-services-top-text {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.no-services-bottom-text {
  color: #C0D9FD;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
.has-services-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.count-container {
  display: flex;
  gap: 8px;
  align-items: center;
}
.cart-icon {
  font-size: 24px;
  margin: 0;
}
.count-text {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.has-services-text {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.icon-chevron {
  color: white;
  font-size: 32px;
  margin: 0;
  transform: rotate(270deg);
}
</style>
