<script setup lang="ts">
  import type { ContentInterface } from '~/EastclinicVueApi';

  interface Props {
    photo: ContentInterface
    hasVideo: boolean
    url: string
    reviewsCount: number
  }

  const props = defineProps<Props>();

  const getReviewsCase = computed(() => {
    const count = props.reviewsCount;
    const titles = ['отзыв', 'отзыва', 'отзывов'];
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]];
  });
</script>

<template>
  <div class="photo-container">
    <NuxtLink :to="url">
      <img
        class="doctor-photo"
        loading="lazy"
        :src="photo.url"
        :alt="photo.alt"
      >
    </NuxtLink>
    <NuxtLink v-if="hasVideo" :to="url" class="video-button">
      <IconVideoPlay class="icon-video-play" />
    </NuxtLink>
    <NuxtLink v-if="reviewsCount > 0" :to="`${url}#reviews`" class="reviews-count">
      <IconDoctorItemReviews :filled="true" class="icon-reviews" />
      <span class="reviews-text">{{ reviewsCount }} {{ getReviewsCase }}</span>
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">
.photo-container {
  grid-area: photo;
  position: relative;
  width: 232px;
  margin-right: 24px;
  @media (max-width: 768px) {
    width: 138px;
  }
  @media (max-width: 585px) {
    margin-right: 0px;
    margin-left: 16px;
    width: 116px;
  }
}
.doctor-photo {
  width: 232px;
  height: 269px;
  border-radius: 24px;
  @media (max-width: 768px) {
    width: 138px;
    height: 160px;
  }
  @media (max-width: 585px) {
    width: 116px;
    height: 116px;
    border-radius: 75px;
    object-fit: cover;
    object-position: center top;
  }
}
.video-button {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #2D7FF9;
  border-radius: 16px;
  cursor: pointer;
  @media (max-width: 585px) {
    top: 0px;
    right: 0px;
  }
}
.icon-video-play {
  color: white;
  font-size: 14px;
  margin: 0;
}
.reviews-count {
  position: absolute;
  top: 227px;
  right: 8px;
  min-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  border-radius: 24px;
  background-color: white;
  box-shadow: 0px 4px 24px 0px rgba(103, 109, 122, 0.15);
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 768px) {
    top: 118px;
  }
  @media (max-width: 585px) {
    text-wrap: nowrap;
    top: 82px;
    right: 0px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
.icon-reviews {
  font-size: 16px;
  margin: 0;
}
.reviews-text {
  margin-left: 4px;
  color: #2D7FF9;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
</style>
