<script setup lang="ts">
  import type { ClinicInterface } from '~/EastclinicVueApi';

  interface Props {
    clinic: ClinicInterface
  }

  const props = defineProps<Props>();

  const showModal = ref(false);
  const showMap = ref(false);

  const photos = computed(() => {
    const clinic = props.clinic;
    if (!clinic || !clinic.photos) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (clinic.photos as any)['0x0'] as { image: string }[];
  });

  const yandexMap = computed(() => {
    const rawMap = props.clinic.ymap;
    if (!rawMap) {
      return '';
    }
    const regex = /src="([^"]+)"/;
    const match = rawMap.match(regex);

    if (match && match[1]) {
      return match[1];
    }
    return '';
  });

  const toggleMap = () => {
    showMap.value = !showMap.value;
    if (showMap.value) {
      document.documentElement.style.overflowY = 'hidden';
      return;
    }
    document.documentElement.style.overflowY = 'initial';
  };
</script>

<template>
  <div class="doctor-clinic-button" @click.stop="showModal=true">
    <IconDoctorMap :filled="true" class="icon-map" />
    <Teleport v-if="showModal" to="body">
      <div class="modal">
        <div class="title-container">
          <span class="title-text">Ист Клиника <span style="text-wrap: nowrap">{{ clinicGetAddressInAdpositionalCase(clinic.node_address) }}</span></span>
          <div class="close-container" @click="showModal = false">
            <IconClose class="icon-close" />
          </div>
          <div class="mobile-line"></div>
        </div>
        <ScrollCarousel
          class="carousel-container"
          item-gap="8px"
          :show-arrows="true"
          :item-width="160"
        >
          <div class="carousel-yandex-map" style="position: relative;" @click="toggleMap">
            <img
              v-if="clinic?.id === 1"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-1.webp"
              draggable="false"
            >
            <img
              v-else-if="clinic?.id === 2"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-2.webp"
              draggable="false"
            >
            <img
              v-else-if="clinic?.id === 3"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-3.webp"
              draggable="false"
            >
            <img
              v-else-if="clinic?.id === 6"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-6.webp"
              draggable="false"
            >
            <img
              v-else-if="clinic?.id === 18"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-18.webp"
              draggable="false"
            >
            <img
              v-else-if="clinic?.id === 23"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-23.webp"
              draggable="false"
            >
            <img
              v-else-if="clinic?.id === 45"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-45.webp"
              draggable="false"
            >
            <div class="white-circle clinic-gallery-map-button">
              <IconMapOpen style="width: 20px;height: 20px; margin: 0px;" />
            </div>
          </div>
          <img
            v-for="photo in photos"
            :key="photo.image"
            class="carousel-photo"
            :src="photo.image"
            alt=""
            draggable="false"
          >
        </ScrollCarousel>
        <div class="clinic-info-container">
          <div class="clinic-info-item">
            <div class="icon-container">
              <IconHomeSmile class="icon" filled />
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span class="clinic-info-text" v-html="clinic.address"></span>
          </div>
          <div class="clinic-info-item text-truncate">
            <div class="icon-container">
              <IconClinicMetro class="icon" filled />
            </div>
            <ClinicCircleColor :color="clinic.node_color" />
            <span class="clinic-info-text" style="margin: 0px 4px">{{ clinic.node_address }}</span>
            <IconDirectionsWalk class="icon-walk" filled />
            <span class="distance">{{ clinicGetTextSecondary(clinic?.way, clinic?.way_time) }}</span>
          </div>
          <div class="clinic-info-item">
            <div class="icon-container">
              <IconClock class="icon" filled />
            </div>
            <span class="clinic-info-text">{{ clinic.clinic_hours }}</span>
          </div>
          <div class="clinic-info-item">
            <div class="icon-container">
              <IconDisabled class="icon" filled />
            </div>
            <span class="clinic-info-text">{{ Boolean(clinic?.clinic_pandus)? 'Пандус есть':'Пандуса нет' }}</span>
          </div>
          <div class="clinic-info-item">
            <div class="icon-container">
              <IconParking style="width: 20px;" class="icon" filled />
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-if="clinic.clinic_parking" class="clinic-info-text" v-html="clinic.clinic_parking"></span>
            <span v-else class="clinic-info-text">Нет информации</span>
          </div>
        </div>
      </div>
      <div class="overlay" @click.stop="showModal = false"></div>
    </Teleport>
    <Teleport v-if="showMap" to="body">
      <div class="map-overlay" @click.stop="toggleMap">
        <div class="map-container">
          <div class="map">
            <component
              :is="'script'"
              :src="yandexMap"
              async
            />
            <div class="close-map-button">
              Закрыть
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<style scoped lang="scss">
.doctor-clinic-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 12px;
  background-color: #F2F3F6;
  cursor: pointer;
}
.icon-map {
  font-size: 24px;
  margin: 0;
}
.modal {
  z-index: 102;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 584px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 2px 24px 0px rgba(135, 143, 162, 0.15);
  padding: 32px;
  border-radius: 30px;
  overflow: auto;
  max-height: 100vh;
  @media (max-width: 584px) {
    transform: translate(-50%, -30%);
    width: 100%;
    height: 100%;
    padding: 20px;
    justify-content: start;
  }
}
.overlay {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.close-container {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #F2F3F6;
  cursor: pointer;
}
.icon-close {
  font-size: 20px;
  margin: 0;
  color: #262633;
}
.title-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.title-text {
  color: #171E36;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  @media (max-width: 584px) {
    color: #262633;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.05px;
  }
}
.clinic-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-color: #F2F3F6;
  border-radius: 999px;
  margin-right: 12px;
}
.icon {
  font-size: 24px;
  margin: 0px;
}
.clinic-info-item {
  display: flex;
  align-items: center;
}
.text-truncate {
  text-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.clinic-info-text {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.metro-circle {
  border-radius: 6px;
  width: 8px;
  height: 8px;
  padding: 0px 1px;
}
.m-1 {
  background-color: #ee2d24;
}
.m-2 {
  background-color: #58ba36;
}
.m-3 {
  background-color: #0078bf;
}
.m-6 {
  background-color: #F48335;
}
.m-d1 {
  background-color: #f6a600;
}
.m-myt {
  background-color: #3399cc;
}
.m-lyb {
  background-color: #8F479D;
}
.icon-walk {
  font-size: 14px;
  margin: 0px;
}
.distance {
  color: #878FA2;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.carousel-container {
  margin-top: 24px;
  margin-bottom: 24px;
}
.carousel-photo {
  min-width: 160px;
  height: 140px;
  border-radius: 24px;
  object-fit: cover;
}
.carousel-yandex-map {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 24px;
  cursor: pointer;
}
.clinic-gallery-map-button {
  position: absolute;
  top: 12px;
  right: 12px;
}
.map-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: rgba(0, 0, 0, 0.5);
}
.map-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 600px;
  height: 600px;
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    transform: none;
    left: 0;
    top: 0;
  }
}
.map {
  position: relative;
  width: 600px;
  height: 600px;
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
}
.close-map-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2D7FF9;
  border-radius: 15px;
  color: white;
  text-align: center;
  padding: 0px 20px;
  width: 120px;
  height: 40px;
  bottom: 50px;
  left: 78%;
  cursor: pointer;
  @media (max-width: 767px) {
    left: calc(95% - 120px);
    bottom: 60px;
  }
}
.mobile-line {
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 32px;
  height: 4px;
  border-radius: 3px;
  opacity: 0.2;
  background-color: #000;
  @media (min-width: 584px) {
    display: none;
  }
}
</style>
