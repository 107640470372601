<script setup lang="ts">
  interface Props {
    previousGroup?: string|null
    currentGroup: string
  }
  const props = defineProps<Props>();

  const pageInfoStore = usePageInfoStore();
  const { currentClinic } = storeToRefs(useClinicsStore());
  const { pageInfo } = storeToRefs(pageInfoStore);
  const { currentRoute } = useRouter();

  const titleClinicOnlyPlace = computed(() => {
    if (!currentClinic.value) {
      return 'в Ист Клинике';
    }
    let titleClinicReplaced = (currentClinic.value.full_name) ? currentClinic.value.full_name.replace('Ист Клиник ', '') : '';
    titleClinicReplaced = titleClinicReplaced.replace('Прием ', '');
    titleClinicReplaced = titleClinicReplaced.replace('Клиника', 'Клиники');
    return titleClinicReplaced;
  });

  const newGroup = computed(() => {
    if (props.previousGroup === props.currentGroup) {
      return null;
    }
    return props.currentGroup;
  });

  const getTitleDoctor = (title?: string, menutitle?: string) => {
    if (!title || !menutitle || currentRoute.value.name === 'ClinicPage') {
      return 'Врачи';
    }
    const titleWords = title.split(' ');
    const menutitleWords = menutitle.split(' ');

    const wordCount = menutitleWords.length;
    const result = titleWords.slice(0, wordCount);
    return result.join(' ');
  };
</script>

<template>
  <div v-if="!previousGroup && currentGroup === 'similarDoctors'" class="main-container header__content__title pb-7">
    <h2 class="other-doctors-group-text">
      Похожие врачи в клинике
    </h2>
  </div>
  <div
    v-else-if="!previousGroup && currentGroup !== 'doctorsHealth' && currentGroup !== 'doctorsHealthAndClinic' && pageInfo?.type !== 'dismissDoctor'"
    class="main-container no-doctors"
  >
    <span>К сожалению в {{ titleClinicOnlyPlace.replace('Клиники', 'Клинике') }} нет таких врачей.</span>
    <div>
      <span>Позвоните нам по телефону </span>
      <NuxtLink :to="pageInfoStore.getLinkPhone">
        <span style="color: #2D7FF9" class="phone no-wrap text-semibold">{{ pageInfoStore.getFormattedMainPhone }}</span>
      </NuxtLink>
      <span>, расскажите о заболевании, мы подберем нужного вам врача.</span>
    </div>
  </div>
  <div v-if="newGroup === 'doctorsHealthOnline' || newGroup === 'healthTelemed'" class="main-container header__content__title pb-7">
    <h2 class="other-doctors-group-text">
      {{ getTitleDoctor(pageInfo?.title, pageInfo?.menutitle) }} онлайн
    </h2>
  </div>
  <div v-else-if="newGroup === 'doctorsInClinic'" class="main-container header__content__title pb-7">
    <h2 class="other-doctors-group-text">
      Другие врачи {{ titleClinicOnlyPlace }}
    </h2>
  </div>
  <div v-else-if="newGroup === 'doctorsAll' || newGroup === 'replaceDismissDoctors'" class="main-container header__content__title pb-7">
    <h2 class="other-doctors-group-text">
      Другие врачи Ист Клиники
    </h2>
  </div>
  <div v-else-if="newGroup === 'doctorsHealthOtherClinics'" class="main-container header__content__title pb-7">
    <h2 class="other-doctors-group-text">
      {{ getTitleDoctor(pageInfo?.title, pageInfo?.menutitle) }} в других клиниках
    </h2>
  </div>
</template>

<style scoped lang="scss">
.no-doctors {
  margin: 32px auto;
  @media (max-width: 1023px) {
    margin: 24px 0;
  }
}
.other-doctors-group-text {
  color: #171E36;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin: 32px 0;
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 26px;
    margin: 24px 0;
  }
}
</style>
