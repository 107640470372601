<script setup lang="ts">
  import doctorInfoFirstPhotoBySize from '~/utils/doctorInfo/FirstPhotoBySize';
  import type { IDoctor } from '~/EastclinicVueApi';

  import doctorInfoSpecialsList from '~/utils/doctorInfo/SpecialsList';
  const bookingStore = useBookingStore();
  const doctor = computed<IDoctor|null>(() => bookingStore.doctor);
  const photo120x120 = doctorInfoFirstPhotoBySize(doctor.value as IDoctor, '120x120');
  const specials = doctorInfoSpecialsList(doctor.value as IDoctor);
</script>

<template>
  <div class="booking__dialog__doctor-card">
    <div class="booking__dialog__doctor-card_photo">
      <img :src="photo120x120?.url" :alt="doctor?.fullname">
    </div>
    <div class="booking__dialog__doctor-card_info">
      <div class="text-semibold">
        {{ doctor?.fullname }}
      </div>
      <div v-if="specials" class="booking__dialog__doctor-card_info_desc">
        {{ doctor?.main_specials?.slice(0, 3).join(' · ') }}
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
