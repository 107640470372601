<template>
  <div class="size skeleton"></div>
</template>

<style lang="scss" scoped>
  .size {
    width: 100%;
    height: 340px;
    border-radius: 30px;
    margin-bottom: 16px;
  }
  .skeleton {
    background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0) 0 0/ 100vh 100% fixed;
    animation: bgslide 6s infinite linear
  }

  @keyframes bgslide {
    to {
      background-position: 100vw 0
    }
}
</style>
