<script setup lang="ts">
  import type { ServiceData } from '~/EastclinicVueApi';

  interface Props {
    favoriteService: ServiceData
    services: ServiceData[]
    id: number
    isCabinet?: boolean
  }

  const props = defineProps<Props>();

  const bookingStore = useBookingStore();

  const devScheduleStore = useDevScheduleStore();

  const clinicsStore = useClinicsStore();

  const doctorsStore = useDoctorsStore();

  const showModalServices = ref(false);

  const openBookingWithServices = () => {
    showModalServices.value = false;

    const { onLoaded } = useYandexMetrika();

    onLoaded((ym) => {
      ym.reachGoal('service_booking');
    });

    bookingStore.setBookingData({ doctorId: props.id });
    bookingStore.setBookingFormBlocks({
      showDoctorBlock: true,
      showServicesBlock: true,
      showClinicBlock: true,
      showScheduleBlock: true
    });

    const schedules = devScheduleStore.schedulesByDoctorId.get(props.id);

    if (schedules && schedules.length > 0) {
      bookingStore.clinic = clinicsStore.getClinic(schedules[0].clinicId);
      bookingStore.slot = schedules[0].days.find((d) => d.slots.length > 0)?.slots[0].timestamp ?? 0;

      devScheduleStore.maybeDeletedSlot = {
        doctorId: props.id,
        clinicId: schedules[0].clinicId,
        day: schedules[0].days.find((d) => d.slots.length > 0)?.day ?? 0,
        timestamp: schedules[0].days.find((d) => d.slots.length > 0)?.slots[0].timestamp ?? 0
      };
    }

    if (props.isCabinet) {
      bookingStore.bookingOnlyMessage = true;
    } else {
      bookingStore.bookingOnlyMessage = false;
    }
    bookingStore.setShowModalBooking(true);
  };

  const commentBody = computed(() => {
    return doctorsStore.tryParseComment(props.id);
  });
</script>

<template>
  <div class="service-container" @click="showModalServices = true">
    <div class="service-top">
      <span class="service-top-text">
        {{ favoriteService.name }} <span style="color: #878FA2">{{ Boolean(favoriteService.option) ? favoriteService.option : favoriteService.default_option }}</span>
      </span>
      <IconChevronDown class="icon-chevron" />
    </div>
    <div class="service-bottom">
      <span v-if="!favoriteService.custom_price || favoriteService.price === favoriteService.custom_price" class="service-price">{{ favoriteService.price }} ₽</span>
      <span v-else-if="favoriteService.custom_price > favoriteService.price" class="service-price">{{ favoriteService.custom_price }} ₽</span>
      <div v-else>
        <span class="service-price sale">{{ favoriteService.price }} ₽</span>
        <span class="sale-container">
          <span class="sale-text">{{ favoriteService.custom_price }} ₽</span>
          <IconDoctorServiceSale filled class="sale-icon" />
        </span>
      </div>
    </div>
    <ClientOnly>
      <LazyOperatorEditor
        v-if="useContext() === 'operator'"
        :comment-body="commentBody"
        :commentable-id="id"
        :service-id="favoriteService.serviceId"
        :comment-id="doctorsStore.doctorsCommentsMap.get(id)?.id"
        :block-id="doctorsStore.tryParseBlockId(id)"
      />
    </ClientOnly>
    <DoctorServicesModal
      v-if="showModalServices"
      :services="services.filter(elem => elem?.weight >= 0)"
      :doctor-id="id"
      @selected="openBookingWithServices"
      @close="showModalServices = false"
    />
  </div>
</template>

<style scoped lang="scss">
.service-container {
  grid-area: service;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  border-radius: 15px;
  background-color: #F2F3F6;
  cursor: pointer;
}
.service-top {
  display: flex;
  justify-content: space-between;
}
.service-top-text {
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.icon-chevron {
  font-size: 24px;
  color: #6B7280;
  margin: 0;
}
.service-price {
  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.sale {
  text-decoration: line-through;
}
.sale-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 10px;
  background-color: #10C44C;
}
.sale-text {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.sale-icon {
  font-size: 16px;
  margin: 0px;
}
</style>
