<script setup lang="ts">
  import type { IDoctor } from '~/EastclinicVueApi';
  import doctorInfoFirstPhotoBySize from '~/utils/doctorInfo/FirstPhotoBySize';
  import doctorInfoSpecialsList from '~/utils/doctorInfo/SpecialsList';
  import doctorInfoFavoriteService from '~/utils/doctorInfo/FavoritService';

  interface Props {
    doctor: IDoctor
  }

  const props = defineProps<Props>();

  const doctorsStore = useDoctorsStore();

  const { schedulesByDoctorId } = storeToRefs(useDevScheduleStore());

  const photo = doctorInfoFirstPhotoBySize(props.doctor, '232x269');

  const speciality = computed(() => {
    return doctorInfoSpecialsList(props.doctor, true);
  });

  const favoriteService = computed(() => {
    return doctorInfoFavoriteService(props.doctor);
  });

  const devSchedules = schedulesByDoctorId.value.get(props.doctor.id) ?? [];
</script>

<template>
  <div class="doctor-item-container">
    <div class="doctor-item-white-container">
      <div class="doctor-info-container">
        <DoctorItemPhoto
          :photo="photo"
          :has-video="doctor?._content?.some((c) => c.typeFile === 'video') ?? false"
          :url="`/${doctor?.uri}`"
          :reviews-count="doctor?.ratingInfo?.count ?? 0"
        />
        <DoctorItemInfo
          :speciality="speciality"
          :full-name="doctor?.fullname ?? ''"
          :url="`/${doctor?.uri}`"
          :chevrons="doctor?.chevrons ?? []"
          :doctor-id="doctor.id"
        />
        <DoctorItemAward
          :award="doctor?.awards?.[1]"
          :marks="doctor?.marks"
        />
        <DoctorItemExperience
          :experience="doctor?.chevrons?.find((c) => c.type === 'experience')?.title"
          :min-age="doctor?.tv__dop_info_vrach"
          :url="`/${doctor?.uri}`"
          :is-pregnant-patient="doctor?.pregnant === 1"
          type="item"
        />
        <DoctorItemService
          v-if="favoriteService"
          :id="doctor.id"
          :favorite-service="favoriteService"
          :services="doctor?.service_data ?? []"
        />
      </div>
      <div class="doctor-calendar-container">
        <DoctorItemCalendar
          :schedules="devSchedules"
          :doctor-id="doctor.id"
          :is-cabinet="Boolean(doctor?.is_cabinet)"
          :out-links="doctor.out_links"
          :show-hot-banner="Boolean(doctor?.is_hot_sale)"
        />
      </div>
    </div>
    <ClientOnly>
      <div v-if="useContext() === 'operator'" class="operator-container">
        <LazyOperatorEditor
          class="doctor-editor"
          :commentable-id="doctor.id"
          :comment-body="doctorsStore.tryParseComment(doctor.id)"
          :comment-id="doctorsStore.doctorsCommentsMap.get(doctor.id)?.id"
          :block-id="doctorsStore.tryParseBlockId(doctor.id)"
        />
        <LazyOperatorEditor
          class="calendar-editor"
          :commentable-id="doctor.id"
          :comment-body="doctorsStore.tryParseComment(doctor.id)"
          :doctor-field="2"
          :comment-id="doctorsStore.doctorsCommentsMap.get(doctor.id)?.id"
          :block-id="doctorsStore.tryParseBlockId(doctor.id)"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<style scoped lang="scss">
.doctor-item-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 40px;
}
.doctor-item-white-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  border-radius: 40px;
  padding: 24px;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  @media (max-width: 585px) {
    padding: 20px;
    border-radius: 30px;
  }
}
.doctor-info-container {
  display: grid;
  grid-template-columns: min-content minmax(min-content, 404px);
  /* grid-template-rows: repeat(4, 1fr); */
  grid-template-areas:
    "photo info"
    "photo award"
    "photo experience"
    "photo service";
  @media (max-width: 585px) {
    grid-template-columns: 1fr min-content;
    grid-template-areas:
    "info photo"
    "award award"
    "experience experience"
    "service service";
  }
}
.doctor-calendar-container {
  margin-left: 32px;
  // margin-left: photo-width + margin-right of photo
  @media (max-width: 1023px) {
    margin-top: 20px;
    margin-left: 256px;
  }
  @media (max-width: 768px) {
    margin-left: 162px;
  }
  @media (max-width: 585px) {
    margin-left: 0px;
  }
}
.slots-width {
  display: flex;
  flex-direction: column;
  max-width: 335px;
  @media (max-width: 1023px) {
    max-width: 404px;
  }
  @media (max-width: 585px) {
    max-width: none;
  }
}
.calendar-text {
  display: block;
  color: #666E81;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
  margin-bottom: 8px;
}

.clinic-map-container {
  display: flex;
  gap: 8px;
}
.operator-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* gap: 100px; */
  background-color: white;
  padding: 24px;
  border-radius: 40px;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 30px;
  }
}
.doctor-editor {
  display: flex;
  width: 60%;
  @media (max-width: 1023px) {
    width: 100%;
  }
}
.calendar-editor {
  display: flex;
  width: 30%;
  @media (max-width: 1023px) {
    width: 100%;
  }
}
</style>
