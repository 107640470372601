<script setup lang="ts">
  import { onClickOutside } from '@vueuse/core';
  import PatientFormView from './PatientFormView.vue';
  import ServicesBlockView from './ServicesBlockView.vue';
  import useIsMobile from '~/composables/useIsMobile';
  import BackLink from '~/UI/BackLink/BackLink.vue';
  import type BookingFormViewProps from '~/components/Booking/interfaces/BookingFormViewProprs';
  import EcButton from '~/UI/Buttons/EcButton.vue';
  import ClinicCardSelectedView from '~/UI/Clinics/views/ClinicCardSelectedView.vue';
  import type { DoctorInterface } from '~/EastclinicVueApi';
  import Checkbox from '~/UI/Inputs/Checkbox.vue';
  import YandexCaptchaWrapper from '~/UI/Yandex/CaptchaWrapper.vue';

  // const props = defineProps<BookingFormViewProps>();
  const bookingStore = useBookingStore();

  const doctor = computed<DoctorInterface|null>(() => {
    return bookingStore.doctor as DoctorInterface | null;
  });
  const scheduleStore = useScheduleStore();

  const patient = bookingStore.Patient;
  const bookingBlocks = bookingStore.bookingFormViewProps as BookingFormViewProps;

  const refBookingDialog = ref(null);
  onClickOutside(refBookingDialog, () => {
    if (!showModalServices.value) {
      bookingStore.showLeaveMessage = true;
      bookingStore.showModalBooking = false;
    }
  });
  const closeDialog = () => {
    bookingStore.showLeaveMessage = true;
    bookingStore.showModalBooking = false;
  };

  const captchaWrapper = ref<HTMLElement | null>(null);
  const error = ref<string>('');

  const submit = () => {
    bookingStore.loading = true;
    error.value = '';
    // пришлось вынести проверку отдельно
    if (!bookingStore.validate()) {
      setTimeout(() => {
        bookingStore.loading = false;
      }, 100);
      return;
    }
    if (captchaWrapper.value) {
      captchaWrapper.value?.captchaSubmit();
    } else {
      setTimeout(() => {
        error.value = 'Что-то пошло не так. Попробуйте обновить страницу и отправить форму ещё раз.';
        bookingStore.loading = false;
      }, 300);
    }
  };
  const showModalServices = ref(false);
  const callbackToPass = () => {
    bookingStore.book();
  };

  const { schedulesByDoctorId } = storeToRefs(useDevScheduleStore());

  const devSchedules = computed(() => {
    if (doctor.value) {
      return schedulesByDoctorId.value.get(doctor.value.id) ?? [];
    }
    return [];
  });

  const { pageInfo } = storeToRefs(usePageInfoStore());

  const formattedDate = computed(() => {
    const formatter = new Intl.DateTimeFormat('ru-RU', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'Europe/Moscow'
    });
    return `${formatter.format(new Date(bookingStore.slot * 1000))}`;
  });
</script>

<template>
  <LazyOperatorBookingForm v-if="useContext() === 'operator'" />
  <div v-else ref="refBookingDialog" :class="{'mobile': false}" class="booking__dialog__wrapper">
    <div
      class="vcard-padding position-relative booking-container"
      :class="{'rounded-xl' : !useIsMobile()}"
    >
      <div v-if="!showModalServices" id="booking__dialog__wrapper" class="booking__dialog__scroll">
        <div class="v-card-container divider">
          <div class="modal-card-title">
            <span class="text-semibold">Запись
              <span v-if="doctor?.is_cabinet">в кабинет диагностики</span>
              <span v-else-if="doctor && bookingBlocks.showDoctorBlock">на приём к врачу</span>
              <span v-else>в клинику</span>
            </span>
            <IconCloseGray
              class="text-color-grey pointer"
              style="width: 15px"
              @click="closeDialog"
            />
          </div>
          <DoctorBooking v-if="bookingBlocks.showDoctorBlock" />
        </div>
        <div class="border-radius-30 scroll">
          <div class="booking__dialog">
            <div v-if="!bookingStore.showBookingScheduleBlock">
              <ServicesBlockView
                v-if="bookingBlocks.showServicesBlock"
                :services="doctor?.service_data ?? []"
                @open-services-list="showModalServices = true"
              />
              <div id="booking-form" class="v-card-container last">
                <!-- inputs -->
                <div v-if="bookingBlocks.showShortFormTitle" class="booking__dialog__item">
                  <div class="booking__dialog__item">
                    <span class="annotation">Оператор колл-центра перезвонит Вам в течение 15 минут для уточнения деталей и подтверждения записи на прием. </span>
                  </div>
                </div>
                <PatientFormView :state-patient="patient" />

                <!-- selected time -->
                <div
                  v-if="bookingBlocks.showScheduleBlock && scheduleStore"
                  class="booking__dialog__item pointer"
                  @click="bookingStore.showBookingScheduleBlock = true"
                >
                  <div class="booking__dialog__card with-icon" :class="{'error-border':!!(scheduleStore?.selectedSlotError)}">
                    <div>
                      <div class="booking__dialog__label">
                        Дата и время приема
                      </div>

                      <span v-if="bookingStore.slot && pageInfo?.type !== 'cabinet'">{{ formattedDate }}</span>
                      <!-- <span v-if="bookingStore.slot && pageInfo?.type !== 'cabinet'">{{ useDateFormat(bookingStore.slot *1000, 'D MMMM, HH:mm', { locales: 'ru-RU' }).value }}</span> -->
                      <span v-else-if="bookingStore.slot && pageInfo?.type === 'cabinet'">{{ formattedDate }}</span>
                      <span v-else class="booking__dialog__card_input-text">Выбрать время</span>
                    </div>

                    <IconPencil class="text-color-grey pointer" />
                  </div>
                  <div v-show="scheduleStore?.selectedSlotError" class="booking__dialog__card_error">
                    <div
                      class="v-messages theme--light error--text"
                      v-html="scheduleStore?.selectedSlotError"
                    >
                    </div>
                  </div>
                </div>
                <!-- /selected time -->
                <!-- selected clinic -->
                <div
                  v-if="bookingBlocks.showClinicBlock"
                  class="booking__dialog__item pointer"
                >
                  <div
                    class="booking__dialog__card with-icon"
                    style="padding-bottom: 7px; padding-top: 7px"
                    :class="{'error-border':!!(scheduleStore?.selectedSlotError)}"
                    @click="bookingStore.showBookingScheduleBlock = true"
                  >
                    <div>
                      <div class="booking__dialog__label">
                        Клиника
                      </div>
                      <div v-if="bookingStore.clinic">
                        <ClinicCardSelectedView :clinic="bookingStore.clinic" />
                      </div>
                      <div v-else class="">
                        <span class="booking__dialog__card_input-text">Выбрать клинику</span>
                      </div>
                    </div>

                    <IconPencil class="text-color-grey pointer" />
                  </div>
                </div>
                <!-- /selected clinic -->

                <!-- <div
                  v-if="bookingStore.errorText"
                  class="booking__dialog__error_wrap"
                >
                  <div
                    class="error--text booking__dialog__error"
                    v-html="bookingStore.errorText"
                  >
                  </div>
                </div> -->
                <Checkbox />
                <ClientOnly>
                  <YandexCaptchaWrapper
                    ref="captchaWrapper"
                    :callback="callbackToPass"
                  />
                </ClientOnly>
                <div v-if="error" class="input-error-message">
                  {{ error }}
                </div>
                <div class="booking__dialog__item">
                  <EcButton
                    :loading="bookingStore.loading"
                    :disable="bookingStore.loading"
                    class="primary full-width shadow-button services-button-container pointer"
                    @click="submit"
                  >
                    <span>Записаться</span>
                  </EcButton>
                </div>
                <!-- <BookingViewsAgreement /> -->
              </div>
            </div>

            <!--clinic schedule block-->
            <div v-if="bookingStore.showBookingScheduleBlock && doctor" class="v-card-container last">
              <BackLink :is-booking="true" @click="bookingStore.showBookingScheduleBlock = false" />
              <div class="booking-calendar-container">
                <DoctorItemCalendar
                  :schedules="devSchedules"
                  :doctor-id="doctor.id"
                  :is-cabinet="Boolean(doctor?.is_cabinet)"
                  :is-booking="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DoctorServicesModal
        v-if="showModalServices"
        :services="doctor?.service_data.filter(elem => elem?.weight >= 0)"
        :from-booking="true"
        @close="showModalServices = false"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.booking__dialog__item_row .v-input__slot {
    height: auto;
}
.booking__dialog__item_row .v-messages {
    min-height: 0;
}
.booking-calendar-container {
  @media (min-width: 585px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
