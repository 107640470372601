<script setup lang="ts">
  import ServicesCartListView from '~/UI/Services/views/ServicesCartListView.vue';
  import type { ServiceData } from '~/EastclinicVueApi';
  defineProps<{services:ServiceData[]}>();
  const emit = defineEmits(['openServicesList']);
  const cart = useCartStore();

  const openServicesListEmit = () => {
    emit('openServicesList', true);
  };
</script>

<template>
  <div class="v-card-container middle divider">
    <div class="booking__dialog__small-header mb-3">
      <span class="text-semibold text-regular">Услуга</span>
      <span v-show="cart.count()" class="annotation">&nbsp; {{ cart.count() }}</span>
      <span v-show="cart.count()" class="annotation float-right pointer" @click="cart.clear()">Удалить всё
        <IconTrash style="height: 11px;" />
      </span>
    </div>
    <div v-if="!cart.count()" class="services-full-width">
      <div class="doctor-info__services_wrap serv">
        <div role="button" aria-haspopup="true" aria-expanded="false" class="doctor-info__services" @click="openServicesListEmit()">
          <div class="doctor-info__services_title serv-title">
            Выберите услугу
          </div>
          <div class="doctor-info__services_price serv-price"></div>
          <div class="doctor-info__services_more serv-more">
            <IconDown style="color: #878fa2; width: 11px; position: relative; top: 2px" />
          </div>
        </div>
      </div>
    </div>

    <ServicesCartListView />
    <div
      v-show="cart.count()"
      class="booking__dialog__add-service mt-4"
      @click="openServicesListEmit()"
    >
      <div class="annotation d-flex align-center">
        <span class="mr-3 text-regular">Добавить услугу</span>
        <IconPlus style="margin-left: 7px; position: relative; top: 1px" />
      </div>
    </div>
    <div
      v-if="!cart.count()"
      class="booking__dialog__item_row d-flex"
      style="margin-top: 16px;"
      @click.prevent="openServicesListEmit()"
    >
      <input type="checkbox" checked class="checkbox">
      <span class="checkbox__label text-color-main pointer">Записаться без услуги</span>
    </div>
  </div>
</template>

<style scoped>
  .checkbox-container {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.checkbox {
  width: 24px;
  height: 24px;
}
.checkbox__label {
  margin-left: 7px;
}
</style>
